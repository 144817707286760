import React, { useEffect } from "react";
import { Modal as AntModal, Input, notification } from "antd";
import gql from "graphql-tag";
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { useBankDetails, BANK_NAMES } from "../../contexts/BankDetailsContext";
import { BLV_CONFIRM_LOAN, BLV_RENEWED_LOAN } from "../../pages/loans/graphql";
import AuthUtils from "../../utils/AuthUtils";
import ApiUtils from "../../utils/ApiUtils";

const SET_LOAN_ACCOUNT_NUMBER = gql`
  mutation setAccountNumber(
    $loan_id: Int!
    $account_number: String!
    $customer_id: Int!
    $federal_customer_id: String!
  ) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: { account_number: $account_number, loan_status: "BLV_VERIFIED" }
    ) {
      id
      account_number
    }
    update_customers_by_pk(
      pk_columns: { id: $customer_id }
      _set: { customer_id: $federal_customer_id }
    ) {
      id
      customer_id
    }
  }
`;

const CHECK_LOAN_ACCOUNT_NUM_EXISTS_IN_DB = gql`
  query getLoansWithProvidedAccountNumber($account_number: String) {
    loans(where: { account_number: { _eq: $account_number } }) {
      account_number
    }
  }
`;

const GET_CUSTOMER_BANK_ID_FROM_LOAN_ID = gql`
  query getCustomerBankIdFromLoanId($id: Int!) {
    loans_by_pk(id: $id) {
      id
      customer{
        id
        customer_id
      }
    }
  }
`;

function isDigitOnly(value) {
  const digitOnlyRegex = /^[0-9]*$/;
  return digitOnlyRegex.test(value);
}

const CreateLoanAccountNumberModal = (props) => {
  const { currentBankName } = useBankDetails();
// console.clear()
  console.log('currentBankName:', currentBankName);
  const {
    data: loanData,
  } = useQuery(GET_CUSTOMER_BANK_ID_FROM_LOAN_ID, {
    variables: {
      id: props?.loan_id,
    },
  });

  const INITIAL_FORM_STATE = {
    customerId: loanData?.loans_by_pk?.customer?.customer_id || "",
    loanAccountNumber: "",
  };

  const [formState, setFormState] = React.useState(INITIAL_FORM_STATE);

  useEffect(() => {
    if (loanData?.loans_by_pk?.customer?.customer_id) {
      setFormState({
        customerId: loanData?.loans_by_pk?.customer?.customer_id || "",
        loanAccountNumber: "",
      })
    }
  }, [loanData?.loans_by_pk?.customer?.customer_id])

  const baseURL = process.env.REACT_APP_FEDERAL_ENDPOINT

  const [BlvConfirmLoan] = useMutation(BLV_CONFIRM_LOAN, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        return notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Loan confirmed",
      });
      // getLoanById({
      //   variables: { id: null }
      // });
      // setSelectedLoan(0);
      // setGoldAppraisalModalOpen(false);
      if (!props?.renewal_id) props.setTrigger(true);
    },
  });
  const [BlvRenewedConfirmLoan] = useMutation(BLV_RENEWED_LOAN, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        return notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Loan Renewed.",
      });
      props.setTrigger(true);
    },
  });
  const [setLoanAccountNumber] = useMutation(SET_LOAN_ACCOUNT_NUMBER, {
    onError(error) {
      setFormState(INITIAL_FORM_STATE);
      // error.graphQLErrors.map(error => {
      //   console.log(error);
      //   return notification.error({
      //     message: "Error!",
      //     description:
      //       "Something went wrong. Please check all details and try again"
      //   });
      // });
      // console.log(error);
      return notification.error({
        message: "Error!",
        description:
          "Something went wrong. Please check all details and try again",
      });
    },
    onCompleted(data) {
      setFormState(INITIAL_FORM_STATE);
      notification.success({
        message: "Success!",
        description: "Loan account number created",
      });
      props.setOpen(false);
      props.setLoanAccountNumberSet(true);
      // console.log("cehck");
      // props.setSelectedLoan(0);

      BlvConfirmLoan({
        variables: {
          loan_id: props.loan_id,
          stored_at: moment(),
        },
      });
      if (props?.renewal_id)
        BlvRenewedConfirmLoan({
          variables: {
            loan_id: props?.renewal_id,
          },
        });

      // setGoldAppraisalModalOpen(false);
    },
  });
  const [checkIfLoanAccNumExistsInDB] = useLazyQuery(
    CHECK_LOAN_ACCOUNT_NUM_EXISTS_IN_DB,
    {
      onError() {
        notification.error({
          message: "Error!",
          description:
            "Some error while calling checkIfLoanAccNumExistsInDB method",
        });
      },
      onCompleted(data) {
        if (data?.loans?.length === 0) {
          const { customerId, loanAccountNumber } = formState;

          // setLoanAccountNumber({
          //   variables: {
          //     loan_id: props.loan_id,
          //     account_number: loanAccountNumber,
          //     customer_id: props.customer_id,
          //     federal_customer_id: customerId,
          //   },
          // });

          ApiUtils.post(
            `/api/v1/loan/${props.loan_id}/confirm`,
            {
              customerId: customerId,
              accountNumber: loanAccountNumber
            },
            {
              baseURL,
              headers: {
                Authorization: `JWT ${AuthUtils.getToken()}`,
              },
            }
          )
            .then(function () {
              setFormState(INITIAL_FORM_STATE);
              notification.success({
                message: "Success!",
                description: "Loan account number created",
              });
              props.setOpen(false);
              props.setLoanAccountNumberSet(true);
              // console.log("cehck");
              // props.setSelectedLoan(0);

              // BlvConfirmLoan({
              //   variables: {
              //     loan_id: props.loan_id,
              //     stored_at: moment(),
              //   },
              // });
              // if (props?.renewal_id)
              //   BlvRenewedConfirmLoan({
              //     variables: {
              //       loan_id: props?.renewal_id,
              //     },
              //   });
            })
            .catch(function (error) {
              return notification.error({
                message: "Error!",
                description:
                  "Something went wrong. Please check all details and try again",
              });
            });

        } else {
          notification.error({
            message: "Error!",
            description:
              "Provided loan account number already exists in database.",
          });
        }
      },
    }
  );

  const formValidation = () => {
    const { customerId, loanAccountNumber } = formState;
console.log('currentBankName 123', currentBankName)
    if (loanAccountNumber?.length === 0 || customerId?.length === 0) {
      return notification.error({
        message: "Error!",
        description: "Please enter all values",
      });
    } else if (currentBankName === BANK_NAMES.TPF && loanAccountNumber?.length != 6) {
      return notification.error({
        message: "Error!",
        description: "Loan account number should have 6 alpha numeric characters",
      });
    } else if ((loanAccountNumber?.length <= 13 || loanAccountNumber?.length >= 16) && currentBankName != BANK_NAMES.TPF) {
      return notification.error({
        message: "Error!",
        description: "Loan account number should have 14-15 digits",
      });
    } else if (currentBankName === BANK_NAMES.FEDERAL && customerId?.length !== 9) {
      return notification.error({
        message: "Error!",
        description: "Federal bank customer ID should have exactly 9 digits",
      });
    } else if (currentBankName === BANK_NAMES.FINCARE && customerId?.length > 20) {
      return notification.error({
        message: "Error!",
        description: "Fincare bank customer ID should have less than 20 digits",
      });
    } else {
      checkIfLoanAccNumExistsInDB({
        variables: { account_number: loanAccountNumber },
      });
    }
  };

  return (
    <AntModal
      width="400px"
      className="modal-title-center"
      //   title="Store gold"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <div>
          <h1>{props.title ?? "Confirm Loan"}</h1>
        </div>
        {/* <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
              </div> */}
        <h4>Loan account creation and collateral linking</h4>
        <p>
          Along with loan account creation, the gold ornaments will also be
          linked to the loan when the Create Loan button is clicked.
          {/* mentioned below. */}
        </p>
        <Input
          id="customer-id"
          value={formState.customerId}
          onChange={(e) => {
            if (isDigitOnly(e?.target?.value)) {
              setFormState((prevState) => ({
                ...prevState,
                customerId: e?.target?.value?.toString(),
              }))
            }
          }}
          placeholder="Customer ID"
          className="w-3/4 mx-0"
          autoComplete="off"
          maxLength={currentBankName === BANK_NAMES.FEDERAL ? 9 : 20}
        />
        <Input
          id="account-number"
          value={formState.loanAccountNumber}
          onChange={(e) => {
            if (isDigitOnly(e?.target?.value)) {
              setFormState((prevState) => ({
                ...prevState,
                loanAccountNumber: e?.target?.value?.toString(),
              }))
            }
          }}
          placeholder="Loan account number"
          className="w-3/4 mx-0"
          autoComplete="off"
          maxLength={15}
        />
        <div className="flex space-x-4 justify-center">
          <button className="cta" onClick={formValidation}>
            Create Loan account
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default CreateLoanAccountNumberModal;
