// DEPS
import React, { useEffect, useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  Spin,
  Popconfirm,
  Steps,
  Tag,
  Modal as AntModal,
  InputNumber,
  notification,
  Empty,
} from 'antd';
import moment from 'moment';

// COMPONENTS
import ConfirmCallbackModal from '../../../components/Modals/confirmCallback';
import Sidebar from '../../../components/Sidebar';
import ErrorPage from '../../../components/ErrorPage';
import StepsContainer from '../../../components/Steps';
import ViewPdf from '../../../components/ViewPdf';

// VIEWS
import GoldAppraisalDetails from '../../../views/goldAppraisalDetails';
import UserKYCDetails from '../../../views/userKYCDetails';
import AgentDetailsView from '../../../views/agentDetails';
import Timestamps from '../../../views/timestamps';
import UserBankAccountDetails from '../../../views/userBankAccountDetails';
import LoanDetails from '../../../views/loanDetails';
import PlanDetails from '../../../views/planDetails';
import CustomerDetails from '../../../views/customerDetails';

// APIS
import {
  GET_LOAN_BY_ID,
  SET_BLC_VERIFIED_STATUS,
  CHECK_SEAL_GROSS_WT,
  BLC_ABORT_LOAN,
  SET_LOAN_RELEASED_STATUS,
  UPDATE_VISIT_STATUS_GOLD_HANDED_OVER,
  CHECK_OTP,
  GENERATE_OTP,
  VALIDATE_OTP,
  UPDATE_VISIT_STATUS_OTP_VALIDATED,
  UPDATE_GOLDS_ADD_SEALED_COVER_GROSS_WT_CHECKER,
} from '../graphql';
import { get_image_url } from '../../../utils';
import SealIntegrityCheck from '../../../views/SealIntegrityCheck';
import _ from 'lodash';
import AuthUtils from '../../../utils/AuthUtils';

//CSS
import './new.css';
import OtpLogs from "../../../views/otpLogDetails";
import { getValueFromCookie } from '../../../utils/cookies';

const { Step } = Steps;

const BLCAllLoans = (props) => {
  const [trigger, setTrigger] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(0);

  const [isGoldStoreModalOpen, setGoldStoreModalOpen] = useState(false);
  const [isValidateOTPModalOpen, setValidateOTPModalOpen] = useState(false);
  const [isGoldAppraisalModalOpen, setGoldAppraisalModalOpen] = useState(false);
  const [isGoldReleaseModalOpen, setGoldReleaseModalOpen] = useState(false);
  const [isValidateReleaseOTPModalOpen, setValidateReleaseOTPModalOpen] =
    useState(false);

  const [images, setImages] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [otp, setOTP] = useState(null);
  const [toggleViewPdfModal, setToggleViewPdfModal] = useState({
    toggle: false,
    url: '',
    title: '',
  });  

  const [sealedCoverGrossWt, setSealedCoverGrossWt] = useState([]);

  const [updateGoldsAddSealedCoverGrossWtChecker] = useMutation(
    UPDATE_GOLDS_ADD_SEALED_COVER_GROSS_WT_CHECKER,
    {
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          // console.log(error);
          notification.error({
            message: 'Error!',
            description:
              'Something went wrong. Please check all details and try again',
          });
        });
      },
      onCompleted(data) {
        // notification.success({
        //   message: "Success!",
        //   description: "OTP validated",
        // });
        setGoldStoreModalOpen(false);
        setGoldAppraisalModalOpen(true);
      },
    }
  );

  const [checkOTP, { data: OTPData }] = useLazyQuery(CHECK_OTP, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (OTPData) {
      if (OTPData.check_otp.is_active) {
        notification.error({
          message: 'Error!',
          description: 'OTP has not been validated yet',
        });
      } else {
        setValidateReleaseOTPModalOpen(false);
        setGoldReleaseModalOpen(true);
      }
    }
  }, [OTPData]);

  const [setVisitStatusOTPValidated] = useMutation(
    UPDATE_VISIT_STATUS_OTP_VALIDATED,
    {
      onError(error) {
        error.graphQLErrors.map((error) => {
          // console.log(error);
          notification.error({
            message: 'Error!',
            description:
              'Something went wrong. Please check all details and try again',
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: 'Success!',
          description: 'OTP validated',
        });
        setValidateOTPModalOpen(false);
        setGoldStoreModalOpen(true);
      },
    }
  );

  const [generateOTP] = useMutation(GENERATE_OTP, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: 'Success!',
        description: 'OTP generated',
      });
      setOTP(data.generate_otp.otp);
      // setValidateReleaseOTPModalOpen(false);
      // setGoldReleaseModalOpen(true);
    },
  });

  const [setLoanReleasedStatus] = useMutation(SET_LOAN_RELEASED_STATUS, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      // notification.success({
      //   message: "Success!",
      //   description: "Gold Released"
      // });
      // setGoldReleaseModalOpen(false);

      let visit_id = loanData.loans_by_pk.visits.filter((visit) => {
        // console.log("visit", visit);
        return visit.type === 'GR';
      });

      updateVisitStatusToGoldHandedOver({
        variables: {
          visit_id: visit_id[0].id,
        },
      });
    },
  });

  const [updateVisitStatusToGoldHandedOver] = useMutation(
    UPDATE_VISIT_STATUS_GOLD_HANDED_OVER,
    {
      onError(error) {
        error.graphQLErrors.map((error) => {
          // console.log(error);
          return notification.error({
            message: 'Error!',
            description:
              'Something went wrong. Please check all details and try again',
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: 'Success!',
          description: 'Gold Released',
        });
        setGoldReleaseModalOpen(false);
      },
    }
  );

  const [updateOTP] = useMutation(VALIDATE_OTP, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      // console.log("data", data);
      // notification.success({
      //   message: "Success!",
      //   description: "OTP validated"
      // });
      // setValidateOTPModalOpen(false);
      // setGoldStoreModalOpen(true);
      if (data.validate_otp.message === 'OTP validated') {
        // setVisitStatusOTPValidated({
        //   variables: {
        //     visit_id: loanData.loans_by_pk.visits[0].id
        //   }
        // });
        notification.success({
          message: 'Success!',
          description: 'OTP validated',
        });
        setValidateOTPModalOpen(false);
        setGoldStoreModalOpen(true);
      } else {
        notification.error({
          message: 'Error!',
          description: 'OTP is invalid',
        });
      }
    },
  });

  const [setBLCVerifiedStatus] = useMutation(SET_BLC_VERIFIED_STATUS, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: 'Success!',
        description: 'Confirmed',
      });
      setGoldAppraisalModalOpen(false);
    },
  });

  // console.log("mutationData", mutationData);

  const [
    getLoanById,
    {
      loading: loanDataLoading,
      data: loanData,
      error: loanQueryError,
      refetch: reFetchLoanById,
    },
  ] = useLazyQuery(GET_LOAN_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted(data){
      if(data && data.loans_by_pk && data.loans_by_pk.renew_from_loan) {
        getOldLoanById({ variables: { id: data.loans_by_pk.renew_from_loan } });
      }
    }
  });

  const [
    getOldLoanById, { loading: loanOldDataLoading, data: loanOldData, error: loanOldQueryError}] = useLazyQuery(GET_LOAN_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache"
  });

  const [BlcAbortLoan] = useMutation(BLC_ABORT_LOAN, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: 'Success!',
        description: 'Loan aborted',
      });
      setGoldAppraisalModalOpen(false);
      setTrigger(true);
    },
  });

  const [checkWeights, { loading: checkingWeight, data: checkWeightResponse }] =
    useLazyQuery(CHECK_SEAL_GROSS_WT, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (sealedCoverGrossWt.length > 0 && checkWeightResponse)
      if (
        checkWeightResponse.golds_aggregate.aggregate.count ===
        loanData.loans_by_pk.golds.length
      ) {
        notification.success({
          message: 'Success!',
          description: 'Weights match',
        });
        setGoldStoreModalOpen(false);
        setGoldAppraisalModalOpen(true);
      } else {
        notification.error({
          message: 'Success!',
          description:
            "Seal weights don't match. Please try again or contact Orocorp for support",
        });
      }
  }, [checkingWeight]);

  useEffect(() => {
    getLoanById({ variables: { id: selectedLoan || 0 } });
  }, [selectedLoan]);

  React.useEffect(() => {
    (async () => {
      if (isGoldStoreModalOpen) {
        let response = await reFetchLoanById();
        if (
          response.data.loans_by_pk.visits[0]?.intermediate_status !==
          'OTP_VALIDATED'
        ) {
          notification.error({
            message: 'Error!',
            description: 'OTP is not validated',
          });
          setGoldStoreModalOpen(false);
          setValidateOTPModalOpen(true);
        }
      }
    })();
  }, [isGoldStoreModalOpen]);

  if (loanQueryError || loanOldQueryError) return <ErrorPage />;

  function getSteps(current) {
    return (
      <StepsContainer
        count={current}
        steps={[
          'REQUEST RECEIVED',
          'GOLD STORED',
          'GOLD RELEASED',
          'LOAN CLOSED',
        ]}
      />
    );
  }

  return (
    <div className='flex flex-row space-x-4'>
      {/* Sidebar */}
      <Sidebar
        props={props}
        index={selectedLoan}
        setSelectedLoan={setSelectedLoan}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      {/* Sidebar */}

      <div className='w-full py-4 space-y-8 all-blc overflow-auto'>
        <div className='flex w-full justify-between space-x-16'>
          <div className='w-6/12 flex space-x-8'>
            {loanData
              ? loanData.loans_by_pk !== null
                ? loanData.loans_by_pk.loan_status === 'CST_APPROVED'
                  ? getSteps(0)
                  : loanData.loans_by_pk.loan_status === 'GOLD_STORED'
                  ? getSteps(1)
                  : loanData.loans_by_pk.loan_status === 'GOLD_RELEASED'
                  ? getSteps(2)
                  : getSteps(4)
                : null
              : null}
          </div>

          {loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='w-1/6 space-y-1 text-right'>
                <h4>LOAN ID: {loanData.loans_by_pk.loan_number}</h4>
                <p className='text-gray-600 font-semibold'>
                  {moment(loanData.loans_by_pk.created_at).format(
                    'DD-MM-YYYY HH:mm'
                  )}
                </p>
                {loanData ? (
                  loanData.loans_by_pk !== null ? (
                    loanData.loans_by_pk.loan_status === 'CST_APPROVED' ? (
                      <Tag color={'yellow'} key='TO BE STORED'>
                        TO BE STORED
                      </Tag>
                    ) : loanData.loans_by_pk.loan_status === 'GOLD_STORED' ? (
                      <Tag color={'green'} key='GOLD STORED'>
                        STORED
                      </Tag>
                    ) : loanData.loans_by_pk.loan_status === 'RENEWED' ? (
                      <Tag color={'green'} key='CLOSED'>
                        RENEWED
                      </Tag>
                    ) : loanData.loans_by_pk.loan_status === 'GOLD_RELEASED' ? (
                      <Tag color={'green'} key='RELEASED' className='mr-0'>
                        GOLD ORNAMENTS RELEASED
                      </Tag>
                    ) : loanData.loans_by_pk.loan_status === 'BLC_VERIFIED' &&
                      loanData.loans_by_pk.renew_from_loan !== null ? (
                      <Tag color={'green'} key='RELEASED' className='mr-0'>
                        RENEWAL REQUESTED
                      </Tag>
                    ) : (
                      <Tag color={'red'} key='CLOSED'>
                        LOAN CLOSED
                      </Tag>
                    )
                  ) : null
                ) : null}
              </div>
            ) : null
          ) : null}
        </div>

        {/* {console.log("loanData", loanData)} */}

        {loanDataLoading ? (
          <Spin />
        ) : loanData ? (
          loanData.loans_by_pk !== null ? (
            <div className='space-y-8'>
              <Timestamps loan_id={loanData.loans_by_pk.id} />
              <div className='flex w-full justify-between items-center'>
                <div className='flex space-x-8 items-center'>
                  {loanData.loans_by_pk.loan_status === 'CST_APPROVED' ? (
                    <>
                      <button
                        type='submit'
                        className='btn-primary hover:shadow-lg'
                        onClick={() => setValidateOTPModalOpen(true)}
                        // onClick={() => setGoldStoreModalOpen(true)}
                        // onClick={() => setGoldAppraisalModalOpen(true)}
                      >
                        Store Gold Ornaments
                      </button>

                      {/* <button
                        type="submit"
                        className="btn-danger hover:shadow-lg"
                        // onClick={() => setValidateOTPModalOpen(true)}
                        // onClick={() => setGoldStoreModalOpen(true)}
                        onClick={() => {
                          BlcAbortLoan({
                            variables: {
                              loan_id: loanData.loans_by_pk.id
                            }
                          });
                        }}
                      >
                        Abort Loan
                      </button> */}
                    </>
                  ) : loanData.loans_by_pk.loan_status === 'GOLD_STORED' &&
                    loanData.loans_by_pk.visits.filter((visit) => {
                      return visit.type === 'GR';
                    }).length > 0 ? (
                    <button
                      type='submit'
                      className='btn-primary hover:shadow-lg'
                      onClick={() => setValidateReleaseOTPModalOpen(true)}
                      // onClick={() => setGoldReleaseModalOpen(true)}
                    >
                      Release Gold Ornaments
                    </button>
                  ) : null}

                  {loanData.loans_by_pk.loan_status !== 'CLOSED' ? (
                    <ConfirmCallbackModal />
                  ) : null}
                </div>
                {loanData?.loans_by_pk?.receipts != null ? (
                  <div className="flex">
                    {loanData?.loans_by_pk?.receipts?.signed_pledge_card?.length > 0 && (
                      <button
                      type="submit"
                      className="text-xs px-3 cta py-2"
                      style={{ minWidth: "220px" }}
                      onClick={() => {
                        setToggleViewPdfModal((prev) => ({
                          ...prev,
                          toggle: true,
                          title: 'Signed Pledge Card',
                          url:
                          loanData.loans_by_pk?.receipts?.signed_pledge_card?.[0]
                        }));
                      }}  
                    >
                      View Signed Pledge Card
                    </button>
                    )}
                    {loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement && (
                      <button
                        type="submit"
                        className="text-xs cta py-2 ml-4"
                        style={{ minWidth: "160px" }}
                        onClick={() => {
                          setToggleViewPdfModal((prev) => ({
                            ...prev,
                            toggle: true,
                            title: 'Signed KFS',
                            url:
                            loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement?.[0]
                          }));
                        }}   
                      >
                        View Signed KFS
                      </button>
                    )}
                  </div>
                ) : null}
                {/* <DownloadLoanDetailsButton loan_id={loanData.loans_by_pk.id} /> */}
              </div>

                {/* Renewal Conditions */}
                {loanOldDataLoading ? (
                <Spin />
              ): loanData && loanOldData && loanData.loans_by_pk && 
                loanData.loans_by_pk.renew_from_loan !== null && 
                loanOldData.loans_by_pk && (
                <div className="w-full flex space-x-8">
                  {/* Loan request */}
                  <LoanDetails
                    title="OLD LOAN DETAILS"
                    loan_number={loanOldData.loans_by_pk.loan_number}
                    loan_type={loanOldData.loans_by_pk.loan_type.loan_type}
                    loan_amount={
                      loanOldData.loans_by_pk.od_amount
                        ? loanOldData.loans_by_pk.od_amount.credit_limit
                        : loanOldData.loans_by_pk.gl_amount
                        ? loanOldData.loans_by_pk.gl_amount.loan_amount
                        : null
                    }
                    gold_gross_weight={parseFloat(
                      loanOldData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.gross_weight;
                      }, 0)
                    ).toFixed(2)}
                    gold_net_weight={parseFloat(
                      loanOldData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.net_weight;
                      }, 0)
                    ).toFixed(2)}
                    gold_actual_net_weight={parseFloat(
                      loanOldData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.actual_net_weight;
                      }, 0)
                    ).toFixed(2)}
                  />
                  {/* Loan request */}

                  {/*Plan details */}
                  <PlanDetails
                    title="OLD PLAN DETAILS"
                    scheme_name={loanOldData.loans_by_pk.plan.scheme_name}
                    min_amt={loanOldData.loans_by_pk.plan.minimum_amount}
                    max_amt={loanOldData.loans_by_pk.plan.maximum_amount}
                    tenure={`${loanOldData.loans_by_pk.plan.tenure} months`}
                    interest={`${loanOldData.loans_by_pk.plan.interest_slab_1} %`}
                    interest_slab_2={`${loanOldData.loans_by_pk.plan.interest_slab_2} %`}
                    interest_slab_3={`${loanOldData.loans_by_pk.plan.interest_slab_3} %`}
                    loan_type={loanOldData.loans_by_pk.loan_type.loan_type}
                  />
                  {/* Plan details */}
                </div>
              )}
              <div className="w-full flex space-x-8">
                {/* Loan request */}
                <LoanDetails
                  loan_number={loanData.loans_by_pk.loan_number}
                  loan_type={loanData.loans_by_pk.loan_type.loan_type}
                  loan_amount={
                    loanData.loans_by_pk.od_amount
                      ? loanData.loans_by_pk.od_amount.credit_limit
                      : loanData.loans_by_pk.gl_amount
                      ? loanData.loans_by_pk.gl_amount.loan_amount
                      : null
                  }
                  gold_gross_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.gross_weight;
                    }, 0)
                  ).toFixed(2)}
                  gold_net_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.net_weight;
                    }, 0)
                  ).toFixed(2)}
                  gold_actual_net_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.actual_net_weight;
                    }, 0)
                  ).toFixed(2)}
                />
                {/* Loan request */}

                {/*Plan details */}
                <PlanDetails
                  scheme_name={loanData.loans_by_pk.plan.scheme_name}
                  min_amt={loanData.loans_by_pk.plan.minimum_amount}
                  max_amt={loanData.loans_by_pk.plan.maximum_amount}
                  tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                    (total, obj) => obj.intervals + total,
                    0
                  )} months`}
                  interest={`   ${Math.max.apply(
                    Math,
                    loanData.loans_by_pk.plan.slab_details.map(function (o) {
                      return o.lender_interest_rate;
                    })
                  )} %`}
                  interest_slab_2={`${loanData.loans_by_pk.plan.interest_slab_2} %`}
                  interest_slab_3={`${loanData.loans_by_pk.plan.interest_slab_3} %`}
                  loan_type={loanData.loans_by_pk.loan_type.loan_type}
                />
                {/* Plan details */}
              </div>

              <CustomerDetails loan_id={loanData.loans_by_pk.id} />

              {loanData.loans_by_pk.renew_from_loan === null && <AgentDetailsView loan_id={loanData.loans_by_pk.id} /> }

              {/* Gold details */}
              <GoldAppraisalDetails
                id={loanData.loans_by_pk.id}
                // hideExport={true}
              />
              {/* Gold details */}

              {/* User Info & KYC */}
              <UserKYCDetails loan_id={loanData.loans_by_pk.id} />
              {/* User Info & KYC */}

              <UserBankAccountDetails loan_id={loanData.loans_by_pk.id} />

              {/* OTP Logs */}
                <OtpLogs loan_number={loanData?.loans_by_pk?.loan_renew_from?.loan_number}></OtpLogs>
              {/* OTP Logs */}

              <ModalGateway>
                {isImageModalOpen && images.length > 0 ? (
                  <Modal
                    onClose={() => {
                      setIsImageModalOpen(!isImageModalOpen);
                    }}
                  >
                    <Carousel views={images} />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          ) : (
            <Empty description='No loan selected' className='p-16' />
          )
        ) : null}
      </div>
      {/* USE THIS MODAL TO VIEW PDF  */}
			{toggleViewPdfModal.toggle && (
				<ViewPdf
					url={toggleViewPdfModal.url}
					visible={toggleViewPdfModal.toggle}
					modalHeading={toggleViewPdfModal.title}
					onClose={() =>
						setToggleViewPdfModal((prev) => ({
							...prev,
							title: '',
							toggle: false,
							url: '',
						}))
					}
				/>
			)}
      {/* <button
        onClick={() => {
          console.log("opening modal");
          setGoldStoreModalOpen(true);
        }}
      >
        Open modal
      </button> */}

      {/* OTP VALIDATION */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isValidateOTPModalOpen}
        footer={[
          <button
            className='btn-primary hover:shadow-lg'
            onClick={() => {
              if (!document.getElementById('PartnerOTP').value) {
                notification.error({
                  message: 'Error!',
                  description: 'OTP is invalid',
                });
              } else if (
                !/^((?![()<>/;[\]{}'"]).)*$/gm.test(
                  document.getElementById('PartnerOTP').value
                )
              ) {
                notification.error({
                  message: 'Error!',
                  description: 'Special Characters are not allowed',
                });
              } else {
                updateOTP({
                  variables: {
                    otp: document.getElementById('PartnerOTP').value,
                  },
                });
              }
            }}
            type='submit'
          >
            Complete agent identity check
          </button>,
          <Popconfirm
            title={`Are you sure you want to cancel?`}
            onConfirm={() => {
              setValidateOTPModalOpen(false);
            }}
            onCancel={() => {}}
            okText='Yes'
            placement='bottomRight'
            cancelText='No'
          >
            <button className='btn-danger hover:shadow-lg'>Close</button>
          </Popconfirm>,
        ]}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Store Gold Ornaments</h1>
          </div>
          <div className='w-4/6'>
            {' '}
            <StepsContainer
              count={0}
              steps={[
                'REQUEST RECEIVED',
                'AGENT VERIFIED',
                'SEAL CHECKED',
                'APPRAISAL VERIFIED',
              ]}
            />
          </div>
          <div className='text-center'>
            <h1>Verify Agent identity</h1>
            <p>Please verify the agent details and enter OTP to proceed.</p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <div className='border bg-white'>
                  <div className='border-b flex p-2 w-full'>
                    {loanData.loans_by_pk.visits
                      .filter((visit) => {
                        return visit.type === 'GR';
                      })
                      .map((vis) => {
                        return (
                          <>
                            {/* {console.log("sd")} */}
                            {/* <p>Test</p> */}
                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT NAME</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_first_name}
                                {vis.agent_last_name}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT ID</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_id}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT MOBILE NO.</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_mobile_number}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>VISIT ID</h5>
                              <p className='font-bold text-black'>
                                {vis.visit_display_id}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all '>
                              <h5>AGENT PHOTO</h5>
                              <button
                                className='underline hover:underline text-yellow-primary'
                                onClick={async () => {
                                  const valid_url = await get_image_url({
                                    url: [
                                      loanData.loans_by_pk.visits[0]
                                        .agent_photo,
                                    ],
                                  });

                                  setImages([
                                    {
                                      source: valid_url,
                                    },
                                  ]);
                                  setIsImageModalOpen(true);
                                }}
                              >
                                Click to view
                              </button>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>

                <div className='text-center w-1/2 mx-auto space-y-2'>
                  <h2>ENTER OTP:</h2>
                  <InputNumber
                    maxLength={4}
                    id='PartnerOTP'
                    placeholder='4 digit OTP'
                    size='large'
                    className='w-1/2 mx-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded'
                  />
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* OTP VALIDATION */}

      {/* SEAL INTEGRITY CHECK */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isGoldStoreModalOpen}
        footer={[
          <button
            className={
              loanData
                ? 'btn-primary hover:shadow-lg'
                : 'bg-gray-100 py-2 px-4 border rounded'
            }
            onClick={() => {
              // console.log("sealed cover gross wt", sealedCoverGrossWt);

              if (sealedCoverGrossWt.length === 0) {
                notification.error({
                  message: 'Error!',
                  description:
                    "Seal weights don't match. Please try again or contact Orocorp for support",
                });
              } else {
                let groupedGoldForSeal = _.groupBy(
                  loanData.loans_by_pk.golds,
                  (gold) => gold.pickup_seal_id
                );

                Object.keys(groupedGoldForSeal).forEach((key) => {
                  let sealed_cover_gross_weight = groupedGoldForSeal[
                    key
                  ].reduce(
                    (total, goldItem) =>
                      total + goldItem.sealed_cover_gross_weight,
                    0
                  );
                  let ornaments = groupedGoldForSeal[key].map((item) => ({
                    gold_display_id: item.gold_display_id,
                    type: item.type,
                    quantity: item.quantity,
                    id: item.id,
                    weight: item.sealed_cover_gross_weight,
                  }));
                  groupedGoldForSeal[key] = {
                    sealed_cover_gross_weight: sealed_cover_gross_weight,
                    ornaments: ornaments,
                    pickup_seal_id: key,
                  };
                });

                let output = sealedCoverGrossWt.map((seal) => {
                  return (
                    seal?.seal_weight >=
                      groupedGoldForSeal[seal.pickup_seal_id]
                        .sealed_cover_gross_weight *
                        0.96 &&
                    seal?.seal_weight <=
                      groupedGoldForSeal[seal.pickup_seal_id]
                        .sealed_cover_gross_weight *
                        1.04
                  );
                });

                let answer = output.includes(false);

                if (answer) {
                  notification.error({
                    message: 'Error!',
                    description:
                      "Seal weights don't match. Please try again or contact Orocorp for support",
                  });
                } else {
                  notification.success({
                    message: 'Success!',
                    description: 'Weights match',
                  });

                  let mutationData = [];

                  Object.keys(groupedGoldForSeal).forEach((key) => {
                    let index = -1;
                    let req_item = sealedCoverGrossWt.filter(
                      (seal, mapIndex) => {
                        if (seal.pickup_seal_id === key) index = mapIndex;
                        return seal.pickup_seal_id === key;
                      }
                    );
                    req_item[0].ornaments.forEach((ornament) => {
                      let obj = {
                        ...ornament,
                        sealed_cover_gross_weight_checker: Number(
                          sealedCoverGrossWt[index].seal_weight
                        ),
                        loan_id: 0,
                        type: 'Necklace',
                        quality: '22 carats',
                        quantity: 0,
                        gross_weight: 0,
                        net_weight: 0,
                        actual_net_weight: 0,
                        sealed_cover_gross_weight: 0,
                        pickup_seal_id: '0',
                        pickup_gold_images: {},
                        pickup_seal_images: {},
                        stone_deduction: 0,
                      };

                      delete obj.weight;
                      delete obj.gold_display_id;

                      mutationData.push(obj);
                    });
                  });

                  console.log('mutationData', mutationData);

                  updateGoldsAddSealedCoverGrossWtChecker({
                    variables: {
                      obj: mutationData,
                    },
                  });
                }

                // checkWeights({
                //   variables: {
                //     loan_id: loanData.loans_by_pk.id,
                //     weights: sealedCoverGrossWt
                //   }
                // });
                // setGoldStoreModalOpen(false);
                // setGoldAppraisalModalOpen(true);
              }
            }}
            type='submit'
          >
            Complete integrity check
          </button>,
          <Popconfirm
            title={`Are you sure you want to cancel?`}
            onConfirm={() => {
              setGoldStoreModalOpen(false);
            }}
            onCancel={() => {}}
            okText='Yes'
            placement='bottomRight'
            cancelText='No'
          >
            <button className='btn-danger hover:shadow-lg'>Close</button>
          </Popconfirm>,
        ]}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Store Gold Ornaments</h1>
          </div>
          <div className='w-full'>
            {' '}
            <StepsContainer
              count={1}
              steps={[
                'REQUEST RECEIVED',
                'AGENT VERIFIED',
                'SEAL CHECKED',
                'APPRAISAL VERIFIED',
              ]}
            />
            <Steps current={1}>
              <Step title='REQUEST RECEIVED' />
              <Step title='AGENT VERIFIED' />
              <Step title='SEAL CHECKED' />
              <Step title='APPRAISAL VERIFIED' />
            </Steps>
          </div>
          <div className='text-center'>
            <h1>Seal integrity check</h1>
            <p>
              Please enter the sealed covers' gross weight weighed at the branch
              to complete the seal integrity check.
            </p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <SealIntegrityCheck
                  id={loanData.loans_by_pk.id}
                  hideExport={true}
                  sealedCoverGrossWt={sealedCoverGrossWt}
                  setSealedCoverGrossWt={setSealedCoverGrossWt}
                />
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* SEAL INTEGRITY CHECK */}

      {/* GOLD APPRAISAL VERIFICATION */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isGoldAppraisalModalOpen}
        footer={null}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Store Gold Ornaments</h1>
          </div>
          <div className='w-4/6'>
            {' '}
            <StepsContainer
              count={2}
              steps={[
                'REQUEST RECEIVED',
                'AGENT VERIFIED',
                'SEAL CHECKED',
                'APPRAISAL VERIFIED',
              ]}
            />
          </div>
          <div className='text-center'>
            <h1>Gold appraisal verification</h1>
            <p>
              Please verify the Oro appraised Gold ornament details with the
              appraisal details at the branch.
            </p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <div className='space-y-2'>
                  {/* <h4>GOLD APPRAISAL DETAILS</h4> */}
                  <div className='flex space-x-4'>
                    <p>
                      TOTAL NUMBER OF ORNAMENTS{' '}
                      {loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.quantity;
                      }, 0)}
                    </p>
                    <p>
                      TOTAL GOLD NET WEIGHT{' '}
                      {parseFloat(
                        loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                          return acc + goldItem.gross_weight;
                        }, 0)
                      ).toFixed(2)}{' '}
                      grams
                    </p>
                  </div>

                  <GoldAppraisalDetails
                    id={loanData.loans_by_pk.id}
                    hideExport={true}
                  />
                </div>

                <div className='flex space-x-4 justify-center'>
                  <button
                    className={
                      loanData
                        ? 'btn-primary hover:shadow-lg'
                        : 'bg-gray-100 py-2 px-4 border rounded'
                    }
                    onClick={() => {
                      // setGoldStoreModalOpen(false);
                      setBLCVerifiedStatus({
                        variables: {
                          id: loanData.loans_by_pk.id,
                          loan_status: 'BLC_VERIFIED',
                          checker_confirmed_at: moment(),
                          // visit_id: loanData.visits[0].id,
                          // visit_status: "OD_GOLD_DEPOSITED"
                        },
                      });
                    }}
                    type='submit'
                  >
                    Complete appraisal verification
                  </button>

                  <Popconfirm
                    title={`Are you sure you want to cancel?`}
                    onConfirm={() => {
                      setGoldAppraisalModalOpen(false);
                    }}
                    onCancel={() => {}}
                    okText='Yes'
                    placement='bottomRight'
                    cancelText='No'
                  >
                    <button className='btn-danger hover:shadow-lg'>
                      Close
                    </button>
                  </Popconfirm>
                </div>

                {/* <p className="text-center">
                  Click here to{" "}
                  <button
                    className="text-red-600 underline hover:underline"
                    onClick={() => {
                      BlcAbortLoan({
                        variables: {
                          loan_id: loanData.loans_by_pk.id,
                          reason: "Weight mismatch"
                        }
                      });
                    }}
                  >
                    abort the loan
                  </button>{" "}
                  due to net weight mismatch
                </p> */}
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* GOLD APPRAISAL VERIFICATION */}

      {/* OTP VALIDATION */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isValidateReleaseOTPModalOpen}
        footer={[
          <button
            className='btn-primary hover:shadow-lg'
            onClick={() => {
              checkOTP({
                variables: {
                  otp: otp,
                  auth_id: getValueFromCookie("user")?.auth_id,  // todo: to be approved by QA
                },
              });
              // console.log("Function not developed yet");
            }}
            type='submit'
          >
            Next
          </button>,
          <Popconfirm
            title={`Are you sure you want to close?`}
            onConfirm={() => {
              setValidateReleaseOTPModalOpen(false);
            }}
            onCancel={() => {}}
            okText='Yes'
            placement='bottomRight'
            cancelText='No'
          >
            <button className='btn-danger hover:shadow-lg'>Close</button>
          </Popconfirm>,
        ]}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Relase Gold Ornaments</h1>
          </div>
          <div className='w-4/6'>
            {' '}
            <StepsContainer
              count={0}
              steps={[
                'REQUEST RECEIVED',
                'AGENT VERIFIED',
                'ORNAMENTS HANDED OVER',
              ]}
            />
          </div>
          <div className='text-center'>
            <h1>Verify Agent identity</h1>
            <p>
              Please verify the agent details and provide the OTP to proceed.
            </p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <div className='border bg-white'>
                  <div className='border-b flex p-2 w-full'>
                    {/* {console.log("t", loanData.loans_by_pk)} */}
                    {loanData.loans_by_pk.visits
                      .filter((visit) => {
                        return visit.type === 'GR';
                      })
                      .map((vis) => {
                        return (
                          <>
                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT NAME</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_first_name}
                                {vis.agent_last_name}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT ID</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_id}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT MOBILE NO.</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_mobile_number}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>VISIT ID</h5>
                              <p className='font-bold text-black'>
                                {vis.visit_display_id}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all '>
                              <h5>AGENT PHOTO</h5>
                              <button
                                className='underline hover:underline text-yellow-primary'
                                onClick={async () => {
                                  const valid_url = await get_image_url({
                                    url: [vis.agent_photo],
                                  });

                                  setImages([
                                    {
                                      source: valid_url,
                                    },
                                  ]);
                                  setIsImageModalOpen(true);
                                }}
                              >
                                Click to view
                              </button>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className='text-center w-1/2 mx-auto space-y-4'>
                  <button
                    className='btn-primary'
                    onClick={() => {
                      let visit_id = loanData.loans_by_pk.visits.filter(
                        (visit) => {
                          // console.log("visit", visit);
                          return visit.type === 'GR';
                        }
                      );

                      // console.log("visit_id", visit_id);

                      generateOTP({
                        variables: {
                          agent_auth_id: visit_id[0].agent_auth_id,
                          visit_id: visit_id[0].id,
                        },
                      });
                      // console.log("Function not developed yet");
                    }}
                  >
                    Generate OTP
                  </button>
                  <p className={otp ? `font-bold text-lg` : 'hidden'}>
                    OTP is: {otp}
                  </p>
                  {/* console.log("Function not developed yet") */}
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* OTP VALIDATION */}

      {/* RELEASE GOLD */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isGoldReleaseModalOpen}
        footer={[
          <button
            className={
              loanData
                ? 'btn-primary hover:shadow-lg'
                : 'bg-gray-100 py-2 px-4 border rounded'
            }
            onClick={() => {
              // setGoldReleaseModalOpen(false);
              setLoanReleasedStatus({
                variables: {
                  id: loanData.loans_by_pk.id,
                  released_at: moment(),
                },
              });
            }}
            type='submit'
          >
            Complete handover
          </button>,
          <Popconfirm
            title={`Are you sure you want to close?`}
            onConfirm={() => {
              setGoldReleaseModalOpen(false);
            }}
            onCancel={() => {}}
            okText='Yes'
            placement='bottomRight'
            cancelText='No'
          >
            <button className='btn-danger hover:shadow-lg'>Close</button>
          </Popconfirm>,
        ]}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Release Gold Ornaments</h1>
          </div>
          <div className='w-4/6'>
            {' '}
            <StepsContainer
              count={1}
              steps={[
                'REQUEST RECEIVED',
                'AGENT VERIFIED',
                'ORNAMENTS HANDED OVER',
              ]}
            />
          </div>
          <div className='text-center'>
            <h1>Handover Gold Ornaments</h1>
            <p>Please verify the gold ornament details and complete handover</p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <GoldAppraisalDetails
                  id={loanData.loans_by_pk.id}
                  hideExport={true}
                />
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* RELEASE GOLD */}
    </div>
  );
};

export default BLCAllLoans;
